<template>
  <bulma-autocomplete id="item-description-autocomplete"
    v-model="innerValue"
    :data="items"
    field="description"
    :class-prop="classProp"
    @input="getItemMasterByTimesStyle"
    @select="select"
    :keep-first="true"
    :expanded="true">
    <template slot-scope="props">
      <div class="is-flex is-justify-content-space-between">
        <span>{{ props.option.description }}</span>
        <div class="tags">
          <span v-if="props.option.itemNo"
            class="tag alpha-code-tag"
            :class="[ innerValue.toLowerCase() === props.option.itemNo.toLowerCase() ? 'is-success' : 'is-light']">{{ props.option.itemNo }}</span>
          <span v-if="props.option.bmItemNo"
            class="tag alpha-code-tag"
            :class="[ innerValue.toLowerCase() === props.option.bmItemNo.toLowerCase() ? 'is-success' : 'is-light']">{{ props.option.bmItemNo }}</span>
          <span v-if="props.option.alphaCode"
            class="tag alpha-code-tag"
            :class="[ innerValue.toLowerCase() === props.option.alphaCode.toLowerCase() ? 'is-success' : 'is-light']">{{ props.option.alphaCode }}</span>
          <span v-if="props.option.bmAlphaCode"
            class="tag alpha-code-tag"
            :class="[ innerValue.toLowerCase() === props.option.bmAlphaCode.toLowerCase() ? 'is-success' : 'is-light']">{{ props.option.bmAlphaCode }}</span>
          <span v-if="props.option.pnpAlphaCode"
            class="tag alpha-code-tag"
            :class="[ innerValue.toLowerCase() === props.option.pnpAlphaCode.toLowerCase() ? 'is-success' : 'is-light']">{{ props.option.pnpAlphaCode }}</span>
        </div>
      </div>
    </template>
  </bulma-autocomplete>
</template>

<script>
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { ItemMasterService } from '@/services'
import _debounce from 'lodash.debounce'

export default {
  name: 'ItemDescriptionAutocomplete',
  components: {
    BulmaAutocomplete
  },
  mixins: [],
  props: {
    value: {
      type: String,
      default: ''
    },
    itemType: {
      type: String,
      default: ''
    },
    timesStyle: {
      type: String,
      default: ''
    },
    scheduleId: {
      type: String,
      default: ''
    },
    bodyId: {
      type: String,
      default: ''
    },
    classProp: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      items: [],
      innerValue: ''
    }
  },
  computed: {},
  watch: {
    innerValue(newVal, oldVal) {
      this.$emit('input', newVal)
    }
  },
  created() {
    this.innerValue = this.value
    this.getItemMasterByTimesStyle()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getItemMasterByTimesStyle: _debounce(async function (query) {
      if (query) {
        this.items = await ItemMasterService.getItemMasterByTimesStyle(this.itemType, this.timesStyle, query, this.scheduleId, this.bodyId)
      } else {
        this.items = []
      }
    }, 300),
    select(selectedItem) {
      if (selectedItem) {
        this.$emit('select', selectedItem)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.alpha-code-tag {
  font-size: 0.55rem;
  &.alpha-code-tag:not(:last-child) {
    margin-right: 0.15rem;
  }
  &.alpha-code-tag:not(body) {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}
</style>
